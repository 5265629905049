import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'

import { HeroBlock, Decoration, SEO } from '../components/shared'

import { FaqBlock } from '../components/pageBlocks'

import { desktopVw, desktopBreakpoint, mobileVw } from '../styles'

const FAQ = ({
  location,
  data: { contentfulPageFaq, contentfulGlobalSite },
}) => {
  const {
    heroblock,
    accordionCollection,
    bottomDecorationMobile,
    bottomDecorationDesktop,
    seoDescription,
    seoTitle,
  } = contentfulPageFaq

  const { blackBrushesAllRecipePages } = contentfulGlobalSite

  return (
    <StyledFAQSection>
      <SEO
        description={seoDescription}
        title={seoTitle}
        location={location}
        pageType='FAQ'
      />
      <HeroWrapper>
        <HeroBlock zIndex={2} data={heroblock} />
        <StyledDecoration>
          {blackBrushesAllRecipePages &&
            blackBrushesAllRecipePages.map((item, i) => {
              return <Decoration key={i} data={item} />
            })}
        </StyledDecoration>
      </HeroWrapper>
      <FaqBlock items={accordionCollection} />
      <Image
        className='mobileOnly'
        fluid={bottomDecorationMobile.image.fluid}
        alt={bottomDecorationMobile.image.fluid}
      />
      <StyledImage
        className='desktopOnly'
        fluid={bottomDecorationDesktop.image.fluid}
        alt={bottomDecorationDesktop.image.fluid}
      />
    </StyledFAQSection>
  )
}

const StyledFAQSection = styled.div``

const HeroWrapper = styled.div`
  .hero-slider {
    height: ${mobileVw(160)};

    @media (min-width: ${desktopBreakpoint}) {
      height: ${desktopVw(568)};
    }
  }

  h1 {
    font-size: ${mobileVw(35)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    h1 {
      font-size: ${desktopVw(70)};
    }
  }

  .swiper-container {
    margin-left: 0;
  }
`

const StyledImage = styled(Image)`
  max-width: ${desktopVw(740)};
  margin-left: 50%;
`

const StyledDecoration = styled.div`
  position: relative;
  background: #000;
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    padding-top: ${desktopVw(0)};
    margin-bottom: ${desktopVw(68)};
  }
`

export const faq = graphql`
  query FAQ($id: String!, $node_locale: String!) {
    contentfulGlobalSite(node_locale: { eq: $node_locale }) {
      blackBrushesAllRecipePages {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
    }
    contentfulPageFaq(id: { eq: $id }) {
      seoDescription
      seoTitle
      id
      slug
      heroblock {
        title
        breadcrumbsTitle
        marginBottom
        background
        breadcrumbs {
          title
          link {
            ...linkTarget
          }
        }
        imagesSlider {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        decoration {
          image {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
      }
      accordionCollection {
        title
        text {
            raw
        }
        icon
      }
      bottomDecorationMobile {
        image {
          fluid(maxWidth: 2400) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      bottomDecorationDesktop {
        image {
          fluid(maxWidth: 2400) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
    }
  }
`

export default FAQ
